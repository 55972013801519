import RickImg from "../assets/images/partner-images-2024-07-31/compressed-images/rick-davidson.webp";
import ShaunImg from "../assets/images/partner-images-2024-07-31/compressed-images/shaun-kentwell.webp";
import DomImg from "../assets/images/partner-images-2024-07-31/compressed-images/dom-wallace-facing-center.webp";
import RyanImg from "../assets/images/partner-images-2024-07-31/compressed-images/ryan-kehl.webp";
import KatieImg from "../assets/images/partner-images-2024-07-31/compressed-images/katie-archdekin-glasses.webp";
import JasonImg from "../assets/images/partner-images-2024-07-31/compressed-images/jason-carson-biggest-smile.webp";
import RobImg from "../assets/images/partner-images-2024-07-31/compressed-images/rob-nash.webp";
import AllPartners from "../assets/images/partner-images-2024-07-31/compressed-images/all-partners-and-tyllah.jpg";
import LinedInIcon from "../assets/images/svg/linkedin.svg";

// Icons
import {
  TrophyIcon,
  UserGroupIcon,
  CogIcon,
  EyeIcon,
  ArrowTrendingUpIcon,
  PuzzlePieceIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";

const values = [
  {
    label: "Excellence",
    description: "We chase excellence in everything we do. Always.",
    icon: TrophyIcon,
  },
  {
    label: "Transparency",
    description: "We are honest and open no matter what.",
    icon: EyeIcon,
  },
  {
    label: "Teamwork",
    description: "We harness our collective strengths to thrive.",
    icon: UserGroupIcon,
  },
  {
    label: "Fun",
    description: "We love what we do and have fun doing it.",
    icon: PuzzlePieceIcon,
  },
  {
    label: "Growth",
    description: "We are dedicated to lifelong learning.",
    icon: ArrowTrendingUpIcon,
  },
  {
    label: "Grit",
    description: "We embrace challenges that test our limits.",
    icon: CogIcon,
  },
  {
    label: "Empathy",
    description: "We care for and understand the feelings of others.",
    icon: HeartIcon,
  },
];

const founders = [
  {
    name: "Rick Davidson",
    position: "CEO",
    img: RickImg,
    linkedIn: "https://www.linkedin.com/in/ricktdavidson/",
    questions: [
      {
        A: "I'm an IT executive with 25 years of experience building exceptional teams and transformational technology solutions. I spent half of my career honing my leadership skills, so I could build a people-first company. I believe in a culture of collaboration, determination and, perhaps most importantly, respect for each other and for our clients.",
      },
      {
        Q: "What is it like to be a RAVLr?",
        A: "RAVLrs are enthusiastic. It's all about getting out of your comfort zone to grow as a professional and as a person. It's also about having fun. Life is short, enjoy the ride!",
      },
    ],
  },
  {
    name: "Shaun Kentwell",
    position: "Head of Advisory and Delivery",
    img: ShaunImg,
    linkedIn: "https://www.linkedin.com/in/shaunkentwell/",
    questions: [
      {
        A: "I'm a technologist and strategist overseeing operations at RAVL. With nearly 20 years of leadership experience, I'm passionate about connecting businesses with the right tech to deliver extraordinary programs.",
      },
      {
        Q: "What is it like to be a RAVLr?",
        A: "We launched RAVL to disrupt the technology services industry, and that's exactly what we're doing, with a best-in-class team, with a culture of excellence and mutual support. RAVLrs thrive on new challenges and the pursuit of mastery (plus the occasional doughnut).",
      },
    ],
  },
  {
    name: "Dom Wallace",
    position: "CTO",
    img: DomImg,
    linkedIn: "https://www.linkedin.com/in/dom-wallace/",
    questions: [
      {
        A: "I'm a technologist and architect with over 15 years of experience transforming how financial businesses build and run their software. I love solving complex problems alongside talented people and creating a RAVL work culture that makes me proud.",
      },
      {
        Q: "What is it like to be a RAVLr?",
        A: "We're passionate about technology, love learning, and are excited to solve tough problems. RAVLrs question assumptions and challenge the latest technology trends.",
      },
    ],
  },
  {
    name: "Ryan Kehl",
    position: "Head of Build",
    img: RyanImg,
    linkedIn: "https://www.linkedin.com/in/ryanjkehl/",
    questions: [
      {
        A: "After more than 15 years developing products, leading teams, and driving transformative enterprise projects, I've reached a point in my career where I need my work to have purpose. I lead people and culture at RAVL, and I want everyone inside and outside the company to benefit from technology as much as I have.",
      },
      {
        Q: "What is it like to be a RAVLr?",
        A: "A RAVLr understands that tech is more than the code you write or the certifications you aspire to. We pursue growth for ourselves and others and deliver exceptional client experiences.",
      },
    ],
  },
  {
    name: "Katie Archdekin",
    position: "Head of Advisory",
    img: KatieImg,
    linkedIn: "https://www.linkedin.com/in/katiearchdekin/",
    questions: [
      {
        A: "I'm an Advisor, leader, and mentor with over 20 years' experience in strategy and general management. Before joining the firm, I worked with the other RAVL partners. I knew this team had the smarts, drive, and integrity to create something extraordinary.",
      },
      {
        Q: "What is it like to be a RAVLr?",
        A: "Being a RAVLr feels like being part of a large family, with shared moments of joy, laughter, hard work, and the occasional squabble. We feel safe debating our ideas because we've cultivated deep trust and respect for one another.",
      },
    ],
  },
  {
    name: "Jason Carson",
    position: "Head of Cloud",
    img: JasonImg,
    linkedIn: "https://www.linkedin.com/in/jasonfcarson/",
    questions: [
      {
        A: "After nearly 20 years in operations, software development and cloud consulting, I joined RAVL to help people from diverse backgrounds excel in tech. I love watching career changers and self-taught tech enthusiasts overcome their barriers to success.",
      },
      {
        Q: "What is it like to be a RAVLr?",
        A: "At RAVL, accountability, high expectations and unwavering support are the norm. We embrace our shared humanity while navigating the ever-evolving world of tech.",
      },
    ],
  },
  {
    name: "Rob Nash",
    position: "Head of Partnerships",
    img: RobImg,
    linkedIn: "https://www.linkedin.com/in/rob-nash/",
    questions: [
      {
        A: "With nearly two decades of experience in financial services with top banks and consulting firms, including roles in business management, operations, and technology, I've come to value the innovation and improved outcomes that partnerships bring. I joined RAVL because of their high-performance, fun culture and dedication to tackling complex challenges and creating sustainable solutions.",
      },
      {
        Q: "What is it like to be a RAVLr?",
        A: "Being a RAVLr is a dynamic mix of speed and initiative, being curious, supporting each other, and achieving meaningful outcomes for clients.",
      },
    ],
  },
];

const About = () => {
  return (
    <div className="container mx-auto">
      {/* Values Section */}
      <section className="text-center p-4 md:p-8">
        <div className="grid grid-cols-1 gap-6 md:gap-11 xl:px-32">
          <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl">
            Our <span className=" text-purple-700">Team</span>. Our
            <span className=" text-purple-700"> Values</span>.
          </h1>
          <img
            src={AllPartners}
            alt="Partners"
            className="w-full md:w-3/4 lg:w-1/2 xl:w-3/5 h-auto rounded-lg justify-center mx-auto"
          />

          {/* Value List */}
          <ul className="flex flex-row flex-wrap basis-1/2 justify-center w-100">
            {values.map((item) => (
              <li
                key={item.label}
                className="py-2 md:p-2 w-full md:w-1/2 lg:w-1/3"
              >
                <div className="card group flex items-center gap-x-6 text-sm leading-6 h-full">
                  {item.icon ? (
                    <div className="flex h-12 w-12 flex-none items-center justify-center">
                      <item.icon
                        className="h-8 w-8 text-gray-600 group-hover:text-purple-700"
                        aria-hidden="true"
                      />
                    </div>
                  ) : null}
                  <div className="flex-auto text-left h-full items-start">
                    <p className="font-bold text-base text-purple-700">
                      {item.label}
                    </p>
                    <p className="text-sm text-gray-600">{item.description}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* Our Story Section */}
      <section className="p-4 md:p-8">
        <div className="flex flex-col items-center gap-6 md:gap-11 xl:px-64">
          <h2 className="text-4xl font-bold text-gray-900 sm:text-5xl mb-6 text-center">
            <span className="text-purple-700">Our</span> Story
          </h2>
          <div className="card lg:p-6 flex flex-col gap-4 lg:gap-6 text-gray-600 text-base sm:text-xl max-w-3xl">
            <div className="flex flex-col gap-4 lg:gap-6 text-center sm:text-left">
              <p className="text-base">
                {" "}
                <span className="text-purple-700">RAVL</span><br />
                {" "}
                <span className="font-bold">origin:</span> ravel, /ˈrav(ə)l/<br />
                {" "}
                <span className="font-bold">definition:</span> verb—to disentangle 
                or resolve, make simpler<br />
              </p>
              <hr class="w-1/4 border-black"></hr>
              <p className="text-purple-700 font-bold">
                About RAVL
              </p>
              <p className="text-base">
                <span className=" text-purple-700 font-bold"> RAVL</span> {" "}
                is boutique technology advisory and engineering firm focused on the financial services industry. 
                Everything we do is focused on helping our clients realize measurable ROI 
                on their technology investments.
              </p>
              <p className="text-base">
              Our name means “to disentangle,” “to make simpler” and that’s 
              just what we do—deliver elegant solutions to complex financial 
              services problems. 
              </p>
            </div>

            <div className="flex flex-col gap-4 lg:gap-6">
              <p className="text-base">
                At RAVL, we involve advisors and engineers on every engagement. 
                Whether we’re modernizing applications, developing AI strategies, 
                transforming DevSecOps or anything else, our {" "}
                <span className="font-bold"> engineers ensure we 
                only propose actionable strategies</span>, and {" "}
                <span className="font-bold"> our advisors make certain 
                we only design, build and deliver technology that solves your actual 
                business problems. </span>
              </p>
            </div>

            
            <div className="flex flex-col gap-4 lg:gap-6">
              <p className="text-purple-700 font-bold">
                How are we different?
              </p>
              <ol className="list-decimal pl-5 text-base">
                <li>
                  We don’t sell inputs (bodies), we take accountability for
                  delivering business/technology {" "} 
                  <span className="font-bold italic">
                    outcomes
                  </span>
                </li>
                <li>
                  We believe a strategy is worthless if it can’t be implemented. 
                  All our advisory engagements include engineers and architects 
                  to ensure our recommendations can truly, and successfully, 
                  be implemented.
                </li>
                <li>
                  Our biggest differentiator is that we leave our clients with mentored, 
                  skilled employees, able to continue the journey and support their 
                  RAVL-built business/technology assets for years to come.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      {/* Our Story Section */}
      <section className="text-center p-4 md:p-8">
        <div className="flex flex-col items-center gap-6 md:gap-11 xl:px-64">
          <h2 className="text-4xl font-bold text-gray-900 sm:text-5xl">
            What is <span className=" text-purple-700">RAVL</span>?
          </h2>
          <div className="card lg:p-6 flex flex-col gap-4 lg:gap-6 text-gray-600 text-base sm:text-xl max-w-3xl text-center sm:text-left">
            <p className="text-base">
              <div className="flex flex-col gap-4 lg:gap-6">
                <div className="text-xl text-purple-700 font-bold gap-4">
                  How we started{" "}
                </div>
                <p>
                  In 2022, our founding partners gathered around our CEO
                  (Rick's) ping-pong table, eager to discuss how we could fix
                  the gaps in technology consulting and engineering. We were all
                  successful in our careers, working with Canada's leading
                  financial institutions and the world's best advisory firms.{" "}
                </p>
                <p>
                  <strong>
                    We loved our work, but we didn't love it when clients weren't{" "}
                    <em>realizing the full value of their investments.</em>
                  </strong>
                </p>
                <p>We knew there was a better way, so we built it.</p>
              </div>
            </p>
          </div>
        </div>
      </section>

      {/* Leadership Section */}
      <section className="p-4 md:p-8">
        <div className="grid grid-cols-1 gap-6 md:gap-11 xl:px-32">
          <div className="flex flex-col items-center gap-4 md:gap-8 text-center max-w-3xl mx-auto">
            <h2 className="text-4xl font-bold text-gray-900 sm:text-5xl">
              Meet Our <span className="text-purple-700">Leadership</span> Team
            </h2>
            <p className="text-gray-600 sm:text-xl">
              Our leadership team has worked together for over a decade. The
              genesis of RAVL was simple: create an environment where great
              technologists work together to build exceptional technology and
              support each other in our quest towards excellence.
            </p>
          </div>

          <div className="flex flex-wrap justify-center gap-4 lg:gap-8">
            {founders.map((founder, index) => (
              <div
                key={founder.name}
                className={`card flex flex-col items-center justify-between text-sm leading-6 w-full md:w-5/12 md:p-8 ${
                  index === founders.length - 1
                    ? "col-span-2 md:col-span-1"
                    : ""
                }`}
              >
                <div className="items-center justify-center">
                  <img
                    className="rounded-lg object-cover w-full h-full"
                    src={founder.img}
                    alt={founder.name}
                  />
                </div>

                <div className="flex flex-col h-full pt-4 lg:pt-8">
                  <div className="flex flex-row justify-between mb-2 md:mb-4">
                    <div>
                      <h3 className="font-bold text-3xl text-gray-600">
                        {founder.name}
                      </h3>
                      <p className="font-bold text-lg text-purple-700">
                        {founder.position}
                      </p>
                    </div>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href={founder.linkedIn}
                      aria-label="LinkedIn"
                    >
                      <img
                        src={LinedInIcon}
                        alt="LinkedIn Logo"
                        className="h-8 w-8"
                        aria-hidden="true"
                      />
                    </a>
                  </div>
                  <div className="grid grid-cols-1 gap-4 rounded-lg">
                    {founder.questions.map((question, idx) => (
                      <div key={idx} className="rounded-lg">
                        <h4 className="font-bold text-lg text-gray-600">
                          {question.Q}
                        </h4>
                        <p className="text-sm text-gray-600">{question.A}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Future All RAVLrs Section */}
    </div>
  );
};
export default About;
